/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable i18next/no-literal-string */
import { Slider, Tooltip, Typography } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { einberufungswochen } from "../fristenrechnerConstants";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

const FourthQuestion = ({
  einberufungsfrist,
  handleEinberufungsfrist,
}: {
  einberufungsfrist: number | number[];
  handleEinberufungsfrist: (
    event: ChangeEvent<{}>,
    value: number | number[]
  ) => void;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.questions}>
      <Typography className={classes.additionsWrapper}>
        Wie lange ist die Einberufungsfrist?{" "}
        <Tooltip title={t("fristenrechner.tooltipFourthQuestion")}>
          <HelpOutline className={classes.tooltipIcon} />
        </Tooltip>
      </Typography>

      <div className={classes.slider}>
        <Slider
          value={einberufungsfrist}
          onChange={handleEinberufungsfrist}
          aria-labelledby="input-slider"
          marks={einberufungswochen}
          step={null}
        />
      </div>
    </div>
  );
};

export default FourthQuestion;
