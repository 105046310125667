import { TemplateVariable } from "../../../common-interfaces/VorlageInterface";
import { templateVariableKey } from "../../../helpers/general/templates/templateVariableKey";
import { useSetUserInputOnCurrentVorlage } from "../../../state/beschlussVorlage/beschlussVorlageSlice";

export const useChangeHandlerForVariable = (
  variable: TemplateVariable
): ((newValue: string) => void) => {
  const key = templateVariableKey(variable);
  const setUserInput = useSetUserInputOnCurrentVorlage();

  return (value) => setUserInput(key, value);
};
