import { versendungsartenTranslateKey } from "./fristenrechnerTypes";

export const versendungsarten: [
  { value: versendungsartenTranslateKey; label: string },
  { value: versendungsartenTranslateKey; label: string },
  { value: versendungsartenTranslateKey; label: string },
  { value: versendungsartenTranslateKey; label: string }
] = [
  { value: "einschreiben", label: "Einschreiben" },
  { value: "post", label: "Einfache Post" },
  { value: "email", label: "Email" },
  { value: "fax", label: "Fax" },
];

export const einberufungswochen = [
  { value: 0, label: "1 Woche", wochenvalue: 1 },
  { value: 33, label: "2 Wochen", wochenvalue: 2 },
  { value: 66, label: "3 Wochen", wochenvalue: 3 },
  { value: 100, label: "4 Wochen", wochenvalue: 4 },
];

export const einberufungswochenTranslate: {
  [key: number]: { number: number; string: string };
} = {
  0: { number: 1, string: "1 Woche" },
  33: { number: 2, string: "2 Wochen" },
  66: { number: 3, string: "3 Woche" },
  100: { number: 4, string: "4 Woche" },
};

export const versendungsartenTranslate: {
  einschreiben: string;
  post: string;
  email: string;
  fax: string;
} = {
  einschreiben: "Einschreiben",
  post: "Einfache Post",
  email: "Email",
  fax: "Fax",
};

export const postlaufzeiten = {
  email: 1,
  postOderEinschreibenInland: 2,
  postOderEinschreibenAusland: 4,
};
