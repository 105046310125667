import { Tooltip } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

export const TooltipFristencalculation = ({
  calculation,
  tooltip,
}: {
  calculation: string;
  tooltip: string;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  return (
    <Tooltip title={tooltip}>
      <div className={classes.additionsWrapper}>
        {calculation}
        <HelpOutline className={classes.tooltipIcon} />
      </div>
    </Tooltip>
  );
};
