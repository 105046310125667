import { addDays, addWeeks, format, subDays, subWeeks } from "date-fns";
import de from "date-fns/esm/locale/de/index.js";
import { postlaufzeiten, versendungsarten } from "../fristenrechnerConstants";

export const formatVersammlungstag = (date: Date | "" | null): "" | string => {
  if (date === "" || date === null) {
    return "";
  } else {
    return `${format(date, "EEEE", { locale: de })}, den ${format(
      date,
      "dd.MM.yyyy",
      { locale: de }
    )}`;
  }
};

export const formatDate = (date: Date | "" | null): "" | string => {
  if (date === "" || date === null) {
    return "";
  } else {
    return `${format(date, "EEEE", { locale: de })}, den ${format(
      date,
      "dd.MM.yyyy",
      { locale: de }
    )}`;
  }
};

export const addDaysToDate = (
  date: Date | "" | null,
  days: number
): "" | Date => {
  if (date === "" || date === null) {
    return "";
  } else {
    return addDays(date, days);
  }
};

export const addWeeksToDate = (
  date: Date | "" | null,
  weeks: number
): "" | Date => {
  if (date === "" || date === null) {
    return "";
  } else {
    return addWeeks(date, weeks);
  }
};

export const substractWeeksFromDate = (
  date: Date | "" | null,
  weeks: number
): "" | Date => {
  if (date === "" || date === null) {
    return "";
  } else {
    return subWeeks(date, weeks);
  }
};

export const substractDaysFromDate = (
  date: Date | "" | null,
  days: number
): "" | Date => {
  if (date === "" || date === null) {
    return "";
  } else {
    return subDays(date, days);
  }
};

export const postlaufzeit = (
  GesellschafterAuchImAusland: string,
  versandart: string
): number => {
  if (
    versandart === versendungsarten[2].value ||
    versandart === versendungsarten[3].value
  ) {
    return postlaufzeiten.email;
  } else if (GesellschafterAuchImAusland === "nein") {
    return postlaufzeiten.postOderEinschreibenInland;
  } else if (GesellschafterAuchImAusland === "ja") {
    return postlaufzeiten.postOderEinschreibenAusland;
  } else {
    return 0;
  }
};
