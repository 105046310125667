import { Tooltip } from "@material-ui/core";
import { endOfDay, isPast } from "date-fns";
import { useTranslation } from "react-i18next";
import { WarningEmoji } from "../../../../helpers/clientSide/emojis";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

export const WarningVersendungOnTimeNotPossible = ({
  chosenVersendungstag,
}: {
  chosenVersendungstag: Date | "" | null;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div>
      {chosenVersendungstag !== "" &&
      chosenVersendungstag !== null &&
      isPast(endOfDay(chosenVersendungstag)) ? (
        <Tooltip
          title={t("fristenrechner.tooltipDispatchOfInvitationMustBeLater")}
        >
          <div className={classes.additionsWrapper}>
            <WarningEmoji className={classes.tooltipIcon} />
          </div>
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
};
