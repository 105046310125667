import { Tooltip } from "@material-ui/core";
import { isPast, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { WarningEmoji } from "../../../../helpers/clientSide/emojis";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

export const WarningVersammlungstagstagTooNear = ({
  versendungsdatumEinladungAlt,
}: {
  versendungsdatumEinladungAlt: Date | "" | null;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div>
      {versendungsdatumEinladungAlt !== "" &&
      versendungsdatumEinladungAlt !== null &&
      isPast(startOfDay(versendungsdatumEinladungAlt)) ? (
        <Tooltip title={t("fristenrechner.tooltipMeetingMustBeLater")}>
          <div className={classes.additionsWrapper}>
            <WarningEmoji className={classes.tooltipIcon} />
          </div>
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
};
