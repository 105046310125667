import { Tooltip } from "@material-ui/core";
import { isSunOrHoliday } from "feiertagejs";
import { useTranslation } from "react-i18next";
import { IndexPointingUpEmoji } from "../../../../helpers/clientSide/emojis";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

export const WarningVersammlungstagSunOrHoliday = ({
  date,
  dateFormatted,
}: {
  date: Date | "" | null;
  dateFormatted: string;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div>
      {date !== "" && date !== null && isSunOrHoliday(date, "ALL") ? (
        <Tooltip title={t("fristenrechner.tooltipMeetingOnSunOrHoliday")}>
          <div className={classes.additionsWrapper}>
            {dateFormatted}{" "}
            <IndexPointingUpEmoji className={classes.tooltipIcon} />
          </div>
        </Tooltip>
      ) : (
        dateFormatted
      )}
    </div>
  );
};
