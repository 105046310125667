import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { useSinglePageByDatabaseId } from "../../queries/wpPage/useSinglePageByDatabaseId";
import { RVOFaqSection } from "../03-organisms/RVOFaqSection/RVOFaqSection";
import RVORegisterTodayCtaSection from "../03-organisms/RVORegisterTodayCtaSection/RVORegisterTodayCtaSection";
import { RVOUrteileSection } from "../03-organisms/RVOUrteileSection/RVOUrteileSection";
import FristenrechnerContent from "../04-templates/Fristenrechner/FristenrechnerPageContent/FristenrechnerContent";
import { FristenrechnerPageDescription } from "../04-templates/Fristenrechner/FristenrechnerPageDescription";
import { FristenrechnerPageHeader } from "../04-templates/Fristenrechner/FristenrechnerPageHeader";

export const FristenrechnerInhalt = (): JSX.Element => {
  const fristenrechnerPage = useSinglePageByDatabaseId(2236);
  const urteile = fristenrechnerPage?.pageFields?.urteile;
  const faqs = fristenrechnerPage?.pageFields?.faqs;
  return (
    <>
      <Seo post={fristenrechnerPage} />

      <FristenrechnerPageHeader page={fristenrechnerPage} />

      <FristenrechnerContent />

      <FristenrechnerPageDescription page={fristenrechnerPage} />

      <RVORegisterTodayCtaSection />

      {faqs?.title && faqs?.questions && (
        <RVOFaqSection
          title={faqs?.title}
          questions={faqs?.questions as GatsbyTypes.WpQuestionFieldsFragment[]}
        />
      )}

      {urteile?.title && urteile?.questions && (
        <RVOUrteileSection
          title={urteile?.title}
          questions={
            urteile?.questions as GatsbyTypes.WpQuestionFieldsFragment[]
          }
        />
      )}
    </>
  );
};

export default FristenrechnerInhalt;
