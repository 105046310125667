import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  LocalizationProvider,
  LocalizationProviderProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useBooleanState } from "@nvon/react-toolbox";
import de from "date-fns/esm/locale/de/index.js";
import dayjs from "dayjs";
import { Link } from "gatsby";
import { t } from "i18next";
import { useCallback, useState } from "react";
import { votingModeFormalPlenum } from "../../../../data/juristicalData/votingModes/votingModes/votingModeFormalPlenum";
import {
  datumEinladung,
  vollversammlungDatum,
} from "../../../../data/vorlagen/vorlagenShared/allTemplateVariables";
import {
  dateFormatLong,
  firstVorlageForNewUsers,
} from "../../../../helpers/clientSide/constants";
import { queryParameterForVotingMode } from "../../../../helpers/general/frontendUrlPaths";
import {
  useCurrentVorlage,
  useSetCurrentVorlage,
} from "../../../../state/beschlussVorlage/beschlussVorlageSlice";
import RVOContainer from "../../../02-molecules/RVOContainer/RVOContainer";
import { useGetVotingModeSlug } from "../../../02-molecules/RVOVotingModeUrlSwitch/getVotingModeSlug";
import FirstQuestion from "../FristenrechnerQuestions/FirstQuestion";
import FourthQuestion from "../FristenrechnerQuestions/FourthQuestion";
import SecondQuestion from "../FristenrechnerQuestions/SecondQuestion";
import ThirdQuestion from "../FristenrechnerQuestions/ThirdQurestion";
import ErgebnisTimeline from "../FristenrechnerResult/ErgebnisTimeline";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";
import ButtonVersendungstagVersammlungstag from "../FristenrechnerSwitch/ButtonVersendungstagVersammlungstag";
import {
  einberufungswochenTranslate,
  versendungsarten,
} from "../fristenrechnerConstants";
import {
  addDaysToDate,
  addWeeksToDate,
  postlaufzeit,
  substractDaysFromDate,
  substractWeeksFromDate,
} from "../fristenrechnerHelperFunctions/dateCalculations";
import { getAddDays193BGB } from "../fristenrechnerHelperFunctions/getAddDays193BGB";
import { getSubDays193BGB } from "../fristenrechnerHelperFunctions/getSubDays193BGB";
import { useChangeHandlerForVariable } from "../useChangeHandlerForVariable";

const FristenrechnerContent = (): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const selectedVorlage = useCurrentVorlage();
  const setCurrentVorlage = useSetCurrentVorlage();
  const getVotingModeSlug = useGetVotingModeSlug();
  const changeInvitationDate = useChangeHandlerForVariable(datumEinladung);
  const changeVersammlungDate =
    useChangeHandlerForVariable(vollversammlungDatum);

  /**
   * As opposed to counting from day of Versendung
   */
  const {
    state: countingFromDayOfVersammlung,
    on: setToCountingFromVersammlung,
    off: setToCountingFromVersendung,
  } = useBooleanState(false);

  const [chosenVersendungsdatum, setChosenVersendungsdatum] = useState<
    "" | Date | null
  >(new Date());

  const [chosenVersammlungstag, setChosenVersammlungstag] = useState<
    Date | null | ""
  >(new Date());

  const vorlageToUse = selectedVorlage || firstVorlageForNewUsers;
  const generatorPath = `${
    vorlageToUse.landingpageGeneratorLink
  }?${queryParameterForVotingMode}=${getVotingModeSlug(
    votingModeFormalPlenum
  )}`;

  const [chosenVersendungsart, setChosenVersendungsart] =
    useState<string>("einschreiben");

  const handleVersendungsart = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setChosenVersendungsart(event.target.value);

  const [ausländischerGesellschafter, setAusländischerGesellschafter] =
    useState<string>("nein");

  const [einberufungsfrist, setEinberufungsfrist] = useState<number | number[]>(
    0
  );

  const callDeadline = Number.isInteger(einberufungsfrist)
    ? einberufungsfrist
    : undefined;
  const callDeadlineString =
    typeof callDeadline === "number" &&
    !Array.isArray(callDeadline) &&
    einberufungswochenTranslate[callDeadline];
  const callDeadlineNum = callDeadlineString && callDeadlineString.number;

  const [checkedAnkündigung, setCheckedAnkündigung] = useState(false);
  const [checkedDetail, setCheckedDetail] = useState(false);

  // Berechnung ausgehend vom gewählten Versendungsdatum für die Einladungen

  const postlaufzeitPauschal = postlaufzeit(
    ausländischerGesellschafter,
    chosenVersendungsart
  );

  const regelmäßigesZugangsdatumLadungVor193BGB = addDaysToDate(
    chosenVersendungsdatum,
    postlaufzeitPauschal
  );

  const additionPostlaufendeNächsterWerktag = getAddDays193BGB(
    regelmäßigesZugangsdatumLadungVor193BGB
  );

  const regelmäßigesZugangsdatumLadung193BGB =
    chosenVersendungsart !== versendungsarten[2].value &&
    chosenVersendungsart !== versendungsarten[3].value
      ? addDaysToDate(
          regelmäßigesZugangsdatumLadungVor193BGB,
          additionPostlaufendeNächsterWerktag
        )
      : regelmäßigesZugangsdatumLadungVor193BGB;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fristbeginnLadungsfrist = addDaysToDate(
    regelmäßigesZugangsdatumLadung193BGB,
    1
  );

  const fristendeLadungsfrist =
    !Array.isArray(einberufungsfrist) && callDeadlineNum
      ? addWeeksToDate(regelmäßigesZugangsdatumLadung193BGB, callDeadlineNum)
      : "";

  const additionFristendeNächsterWerktag = getAddDays193BGB(
    fristendeLadungsfrist
  );

  const fristendeLadungsfrist193BGB = addDaysToDate(
    fristendeLadungsfrist,
    additionFristendeNächsterWerktag
  );

  const frühesterVersammlungstag = addDaysToDate(
    fristendeLadungsfrist193BGB,
    1
  );

  const fristendeErgänzungsfrist = fristendeLadungsfrist193BGB;

  const fristbeginnErgänzungsfrist = substractDaysFromDate(
    fristendeErgänzungsfrist,
    2
  );

  const regelmäßigesZugangsdatumErgänzungsschreiben193BGB =
    substractDaysFromDate(fristbeginnErgänzungsfrist, 1);

  const substractionWerktagToZugangsdatumErgänzungsschreiben = getSubDays193BGB(
    regelmäßigesZugangsdatumErgänzungsschreiben193BGB
  );

  const regelmäßigesZugangsdatumErgänzungsschreibenVor193BGB =
    chosenVersendungsart !== versendungsarten[2].value &&
    chosenVersendungsart !== versendungsarten[3].value
      ? substractDaysFromDate(
          regelmäßigesZugangsdatumErgänzungsschreiben193BGB,
          substractionWerktagToZugangsdatumErgänzungsschreiben
        )
      : regelmäßigesZugangsdatumErgänzungsschreiben193BGB;

  const versendungsdatumErgänzungsschreiben = substractDaysFromDate(
    regelmäßigesZugangsdatumErgänzungsschreibenVor193BGB,
    postlaufzeitPauschal
  );

  // Berechnung ausgehend vom gewählten Versammlungstag

  const fristendeLadungsfrist193BGBAlt = substractDaysFromDate(
    chosenVersammlungstag,
    1
  );

  const substractionWerktagToEndeLadungsfristAlt = getSubDays193BGB(
    fristendeLadungsfrist193BGBAlt
  );

  const fristendeLadungsfristVor193BGBAlt = substractDaysFromDate(
    fristendeLadungsfrist193BGBAlt,
    substractionWerktagToEndeLadungsfristAlt
  );

  const regelmäßigesZugangsdatumLadung193Alt =
    !Array.isArray(einberufungsfrist) && callDeadlineNum
      ? substractWeeksFromDate(
          fristendeLadungsfristVor193BGBAlt,
          callDeadlineNum
        )
      : "";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fristbeginnLadungsfristAlt = addDaysToDate(
    regelmäßigesZugangsdatumLadung193Alt,
    1
  );

  const substractionWerktagToZugangLadungAlt = getSubDays193BGB(
    regelmäßigesZugangsdatumLadung193Alt
  );

  const regelmäßigesZugangsdatumLadungVor193Alt =
    chosenVersendungsart !== versendungsarten[2].value &&
    chosenVersendungsart !== versendungsarten[3].value
      ? substractDaysFromDate(
          regelmäßigesZugangsdatumLadung193Alt,
          substractionWerktagToZugangLadungAlt
        )
      : regelmäßigesZugangsdatumLadung193Alt;

  const versendungsdatumEinladungAlt = substractDaysFromDate(
    regelmäßigesZugangsdatumLadungVor193Alt,
    postlaufzeitPauschal
  );

  const fristendeErgänzungsfristAlt = fristendeLadungsfrist193BGBAlt;

  const fristbeginnErgänzungsfristAlt = substractDaysFromDate(
    fristendeErgänzungsfristAlt,
    2
  );

  const regelmäßigesZugangsdatumErgänzungsschreiben139Alt =
    substractDaysFromDate(fristbeginnErgänzungsfristAlt, 1);

  const substractionWerktagToZugangErgänzungschreibenAlt = getSubDays193BGB(
    regelmäßigesZugangsdatumErgänzungsschreiben139Alt
  );

  const regelmäßigesZugangsdatumErgänzungsschreibenVor139Alt =
    chosenVersendungsart !== versendungsarten[2].value &&
    chosenVersendungsart !== versendungsarten[3].value
      ? substractDaysFromDate(
          regelmäßigesZugangsdatumErgänzungsschreiben139Alt,
          substractionWerktagToZugangErgänzungschreibenAlt
        )
      : regelmäßigesZugangsdatumErgänzungsschreiben139Alt;

  const versendungsdatumErgänzungsschreibenAlt = substractDaysFromDate(
    regelmäßigesZugangsdatumErgänzungsschreibenVor139Alt,
    postlaufzeitPauschal
  );

  const goToGenerator = useCallback(() => {
    setCurrentVorlage(vorlageToUse);

    const dates = countingFromDayOfVersammlung
      ? {
          meeting: chosenVersammlungstag,
          invitation: versendungsdatumEinladungAlt,
        }
      : {
          invitation: chosenVersendungsdatum,
          meeting: frühesterVersammlungstag,
        };

    const meetingDate = dayjs(dates.meeting).format(dateFormatLong) || "";
    const invitationDate = dayjs(dates.invitation).format(dateFormatLong) || "";
    changeVersammlungDate(meetingDate);
    changeInvitationDate(invitationDate);
  }, [
    changeInvitationDate,
    changeVersammlungDate,
    chosenVersammlungstag,
    chosenVersendungsdatum,
    countingFromDayOfVersammlung,
    frühesterVersammlungstag,
    setCurrentVorlage,
    versendungsdatumEinladungAlt,
    vorlageToUse,
  ]);

  return (
    <>
      <RVOContainer
        paddingBottom={false}
        noPaddingTop={true}
        className={[classes.intro, classes.description].join(" ")}
      >
        <Typography>{`${t("fristenrechner.introText1")}`}</Typography>
        <br />
        <Typography>{`${t("fristenrechner.introText2")}`}</Typography>
        <br />
        <Typography>{`${t("fristenrechner.introText3")}`}</Typography>
        <br />
      </RVOContainer>
      <RVOContainer noPaddingTop={true} paddingBottom={true}>
        <LocalizationProvider
          dateAdapter={
            // 🚨 Heavy type assertion. Might cause trouble down the line.
            AdapterDateFns as unknown as LocalizationProviderProps["dateAdapter"]
          }
          locale={de}
        >
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} md={6} lg={7}>
              <Paper elevation={20} className={classes.fristenPaper}>
                <div className={classes.switchesWrapper}>
                  <div className={classes.detailansichtSwitchWrapper}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={checkedDetail}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setCheckedDetail(event.target.checked);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      labelPlacement="start"
                      label={`${t("fristenrechner.switchDetail")}`}
                    />
                  </div>

                  <div className={classes.detailansichtSwitchWrapper}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={checkedAnkündigung}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setCheckedAnkündigung(event.target.checked);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      labelPlacement="start"
                      label={`${t("fristenrechner.switchTOP")}`}
                    />
                  </div>
                </div>
                <ErgebnisTimeline
                  checkedAnkündigung={checkedAnkündigung}
                  checkedDetail={checkedDetail}
                  buttonSwitch={countingFromDayOfVersammlung}
                  chosenVersendungsdatum={chosenVersendungsdatum}
                  postlaufzeitPauschal={postlaufzeitPauschal}
                  regelmäßigesZugangsdatumLadungVor193BGB={
                    regelmäßigesZugangsdatumLadungVor193BGB
                  }
                  additionPostlaufendeNächsterWerktag={
                    additionPostlaufendeNächsterWerktag
                  }
                  regelmäßigesZugangsdatumLadung193BGB={
                    regelmäßigesZugangsdatumLadung193BGB
                  }
                  fristendeLadungsfrist={fristendeLadungsfrist}
                  additionFristendeNächsterWerktag={
                    additionFristendeNächsterWerktag
                  }
                  fristendeLadungsfrist193BGB={fristendeLadungsfrist193BGB}
                  frühesterVersammlungstag={frühesterVersammlungstag}
                  fristendeErgänzungsfrist={fristendeErgänzungsfrist}
                  fristbeginnErgänzungsfrist={fristbeginnErgänzungsfrist}
                  substractionWerktagToZugangsdatumErgänzungsschreiben={
                    substractionWerktagToZugangsdatumErgänzungsschreiben
                  }
                  regelmäßigesZugangsdatumErgänzungsschreiben193BGB={
                    regelmäßigesZugangsdatumErgänzungsschreiben193BGB
                  }
                  regelmäßigesZugangsdatumErgänzungsschreibenVor193BGB={
                    regelmäßigesZugangsdatumErgänzungsschreibenVor193BGB
                  }
                  versendungsdatumErgänzungsschreiben={
                    versendungsdatumErgänzungsschreiben
                  }
                  chosenVersammlungstag={chosenVersammlungstag}
                  fristendeLadungsfrist193BGBAlt={
                    fristendeLadungsfrist193BGBAlt
                  }
                  substractionWerktagToEndeLadungsfristAlt={
                    substractionWerktagToEndeLadungsfristAlt
                  }
                  fristendeLadungsfristVor193BGBAlt={
                    fristendeLadungsfristVor193BGBAlt
                  }
                  regelmäßigesZugangsdatumLadung193Alt={
                    regelmäßigesZugangsdatumLadung193Alt
                  }
                  substractionWerktagToZugangLadungAlt={
                    substractionWerktagToZugangLadungAlt
                  }
                  regelmäßigesZugangsdatumLadungVor193Alt={
                    regelmäßigesZugangsdatumLadungVor193Alt
                  }
                  versendungsdatumEinladungAlt={versendungsdatumEinladungAlt}
                  fristendeErgänzungsfristAlt={fristendeErgänzungsfristAlt}
                  fristbeginnErgänzungsfristAlt={fristbeginnErgänzungsfristAlt}
                  regelmäßigesZugangsdatumErgänzungsschreiben139Alt={
                    regelmäßigesZugangsdatumErgänzungsschreiben139Alt
                  }
                  substractionWerktagToZugangErgänzungschreibenAlt={
                    substractionWerktagToZugangErgänzungschreibenAlt
                  }
                  regelmäßigesZugangsdatumErgänzungsschreibenVor139Alt={
                    regelmäßigesZugangsdatumErgänzungsschreibenVor139Alt
                  }
                  versendungsdatumErgänzungsschreibenAlt={
                    versendungsdatumErgänzungsschreibenAlt
                  }
                  ausländischerGesellschafter={ausländischerGesellschafter}
                  chosenVersendungsart={chosenVersendungsart}
                  einberufungsfrist={einberufungsfrist}
                />
                <Box className={classes.goToGenerator}>
                  <Button
                    variant="contained"
                    onClick={goToGenerator}
                    component={Link}
                    to={generatorPath}
                  >
                    {t("fristenrechner.takeToInvitation")}
                  </Button>
                  <Typography variant="subtitle2">
                    {t("fristenrechner.takeToInvitationSubtext")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            <Grid item={true} xs={12} md={6} lg={5}>
              <ButtonVersendungstagVersammlungstag
                {...{
                  countingFromDayOfVersammlung,
                  setToCountingFromVersammlung,
                  setToCountingFromVersendung,
                }}
              />

              <FirstQuestion
                buttonSwitch={countingFromDayOfVersammlung}
                chosenVersammlungstag={chosenVersammlungstag}
                handleVersammlungstag={(newValue: Date | null | "") => {
                  setChosenVersammlungstag(newValue);
                }}
                chosenVersendungsdatum={chosenVersendungsdatum}
                handleVersendungstag={(newValue: Date | "" | null) => {
                  setChosenVersendungsdatum(newValue);
                }}
              />

              <SecondQuestion
                chosenVersendungsart={chosenVersendungsart}
                handleVersendungsart={handleVersendungsart}
              />

              <ThirdQuestion
                chosenVersendungsart={chosenVersendungsart}
                ausländischerGesellschafter={ausländischerGesellschafter}
                handleAusländischerGesellschafter={(
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  setAusländischerGesellschafter(event.target.value);
                }}
              />

              <FourthQuestion
                einberufungsfrist={einberufungsfrist}
                handleEinberufungsfrist={(
                  event,
                  newValue: number | number[]
                ) => {
                  setEinberufungsfrist(newValue);
                }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </RVOContainer>
    </>
  );
};

export default FristenrechnerContent;
