import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useFristenrechnerStyles = makeStyles((theme: Theme) =>
  createStyles({
    intro: {
      paddingBottom: theme.spacing(5),
      paddingTop: theme.spacing(6),
    },
    description: {
      "& > *": {
        paddingLeft: theme.spacing(20),
        paddingRight: theme.spacing(20),
        color: theme.palette.primary.dark,
        [theme.breakpoints.only("md")]: {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
        },
        [theme.breakpoints.only("sm")]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
        [theme.breakpoints.only("xs")]: {
          marginTop: theme.spacing(3),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
        "& > p, & > h2, & > h3, & > h4, & > ol, & > ul": {
          color: theme.palette.primary.dark,
          "& a:link, & a:visited, & a:active": {
            textDecoration: "underline",
            color: theme.palette.primary.dark,
          },
          "& a:hover": {
            color: theme.palette.primary.light,
            textDecoration: "underline",
          },
        },
        "& > h2": {
          fontSize: theme.spacing(2),
          marginTop: `${theme.spacing(3)} !important`,
          marginBottom: theme.spacing(2),
        },
        "& > h3": {
          marginTop: `${theme.spacing(3)} !important`,
          marginBottom: theme.spacing(2),
        },
        "& > p, & > ul, & ol": {
          fontSize: theme.spacing(1.2),
        },
        "& > ul li, & > ol li": {
          marginBottom: theme.spacing(1),
        },
        "& ul > li": {
          paddingLeft: theme.spacing(0.3),
        },
        "& ol > li": {
          paddingLeft: theme.spacing(0.6),
        },
        "& ul": {
          listStyle: "square",
        },
        "& ul, & ol": {
          margin: theme.spacing(1.5, 0),
          paddingInlineStart: theme.spacing(1.3),
        },
        "& ul ol, & ol ul": { margin: theme.spacing(1, 0) },
      },
    },
    container: {
      "& > *": {
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      },
    },
    fristenRechnerModal: {
      "& > .MuiPaper-root": {
        padding: 0,
      },
    },
    goToGenerator: {
      width: "100%",
      textAlign: "center",
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      rowGap: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },

    timeline: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start !important",
      alignItems: "center !important",
      flexGrow: "0 !important" as unknown as number,
      overflow: "hidden",
    },

    fristenPaper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      userSelect: "none",
      [theme.breakpoints.down("md")]: {
        minHeight: "700px",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "auto",
        overflow: "scroll",
        boxShadow: "none",
      },
    },
    questions: {
      padding: theme.spacing(1),
    },
    form: { padding: `${theme.spacing(1)} 0`, width: "100%" },

    date: {
      paddingBottom: 0,
    },
    innerform: {
      padding: "2px",
      width: "100%",
    },
    slider: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)} `,
      width: "100%",
    },
    detailansichtSwitchWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
    switchesWrapper: {
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: "100%",
    },
    additionsWrapper: {
      display: "inline-block",
      "& > *": {
        verticalAlign: "middle",
      },
    },
    warning: {
      marginTop: `-${theme.spacing(2)}`,
      marginBottom: `${theme.spacing(4)}`,
    },

    tooltipIcon: {
      fontSize: "20px",
      paddingLeft: "4px",
    },
  })
);
