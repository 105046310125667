import {
  addDays,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
} from "date-fns";
import { isHoliday } from "feiertagejs";

// Outputs amount of additional days to get next workingday
export function getAddDays193BGB(fristende: Date | "" | null): number {
  // check if Sunday and no following Holidays
  if (fristende === "" || fristende === null) {
    return 0;
  } else if (isSunday(fristende) && !isHoliday(addDays(fristende, 1), "ALL")) {
    return 1;
  } else if (
    isSunday(fristende) &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    !isHoliday(addDays(fristende, 2), "ALL")
  ) {
    return 2;
  } else if (
    isSunday(fristende) &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    !isHoliday(addDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isSunday(fristende) &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    !isHoliday(addDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else if (
    isSunday(fristende) &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    isHoliday(addDays(fristende, 4), "ALL") &&
    !isHoliday(addDays(fristende, 5), "ALL")
  ) {
    return 5;
  }

  // check if Saturday and on Monday and the following days no Holidays
  else if (isSaturday(fristende) && !isHoliday(addDays(fristende, 2), "ALL")) {
    return 2;
  } else if (
    isSaturday(fristende) &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    !isHoliday(addDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isSaturday(fristende) &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    !isHoliday(addDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else if (
    isSaturday(fristende) &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    isHoliday(addDays(fristende, 4), "ALL") &&
    !isHoliday(addDays(fristende, 5), "ALL")
  ) {
    return 5;
  } else if (
    isSaturday(fristende) &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    isHoliday(addDays(fristende, 4), "ALL") &&
    isHoliday(addDays(fristende, 5), "ALL") &&
    !isHoliday(addDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Friday is Holiday and on Monday and the following days no Holidays
  else if (
    isFriday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(addDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isFriday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    !isHoliday(addDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else if (
    isFriday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    isHoliday(addDays(fristende, 4), "ALL") &&
    !isHoliday(addDays(fristende, 5), "ALL")
  ) {
    return 5;
  } else if (
    isFriday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    isHoliday(addDays(fristende, 4), "ALL") &&
    isHoliday(addDays(fristende, 5), "ALL") &&
    !isHoliday(addDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Thursday is Holiday and on Friday and Monday and the following days no Holidays
  else if (
    isThursday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(addDays(fristende, 1), "ALL")
  ) {
    return 1;
  } else if (
    isThursday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    !isHoliday(addDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else if (
    isThursday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 4), "ALL") &&
    !isHoliday(addDays(fristende, 5), "ALL")
  ) {
    return 5;
  } else if (
    isThursday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 4), "ALL") &&
    isHoliday(addDays(fristende, 5), "ALL") &&
    !isHoliday(addDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Wednesay is Holiday and on Thursday,Friday, Monday and the following days no Holidays
  else if (
    isWednesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(addDays(fristende, 1), "ALL")
  ) {
    return 1;
  } else if (
    isWednesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    !isHoliday(addDays(fristende, 2), "ALL")
  ) {
    return 2;
  } else if (
    isWednesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    !isHoliday(addDays(fristende, 5), "ALL")
  ) {
    return 5;
  } else if (
    isWednesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    isHoliday(addDays(fristende, 5), "ALL") &&
    !isHoliday(addDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Tuesday is Holiday and on Wedenesday, Thursday,Friday, Monday and the following days no Holidays
  else if (
    isTuesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(addDays(fristende, 1), "ALL")
  ) {
    return 1;
  } else if (
    isTuesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    !isHoliday(addDays(fristende, 2), "ALL")
  ) {
    return 2;
  } else if (
    isTuesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    !isHoliday(addDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isTuesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    !isHoliday(addDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Monday is Holiday and on  the following days no Holidays
  else if (
    isMonday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(addDays(fristende, 1), "ALL")
  ) {
    return 1;
  } else if (
    isMonday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    !isHoliday(addDays(fristende, 2), "ALL")
  ) {
    return 2;
  } else if (
    isMonday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    !isHoliday(addDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isMonday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(addDays(fristende, 1), "ALL") &&
    isHoliday(addDays(fristende, 2), "ALL") &&
    isHoliday(addDays(fristende, 3), "ALL") &&
    !isHoliday(addDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else {
    return 0;
  }
}
