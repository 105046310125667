/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable i18next/no-literal-string */
import { Typography } from "@material-ui/core";
import { Group, Send } from "@material-ui/icons";
import { TimelineConnector } from "@material-ui/lab";
import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { useTranslation } from "react-i18next";
import {
  einberufungswochenTranslate,
  versendungsartenTranslate,
} from "../fristenrechnerConstants";
import {
  formatDate,
  formatVersammlungstag,
} from "../fristenrechnerHelperFunctions/dateCalculations";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";
import { ErgebnisTimelineProps } from "./ErgebnisTimelineInterfaces";
import { TooltipFristencalculation } from "./TooltipFristencalculation";
import { WarningVersammlungstagSunOrHoliday } from "./WarningVersammlungstagSunOrHoliday";
import { WarningVersammlungstagstagTooNear } from "./WarningVersammlungstagTooNear";
import { WarningVersendungOnTimeNotPossible } from "./WarningVersendungOnTimeNotPossible";

const ErgebnisTimeline = ({
  checkedDetail,
  checkedAnkündigung,
  buttonSwitch,
  einberufungsfrist,
  chosenVersendungsart,
  chosenVersendungsdatum,
  postlaufzeitPauschal,
  regelmäßigesZugangsdatumLadung193BGB,
  additionPostlaufendeNächsterWerktag,
  additionFristendeNächsterWerktag,
  fristendeLadungsfrist193BGB,
  frühesterVersammlungstag,
  substractionWerktagToZugangsdatumErgänzungsschreiben,
  regelmäßigesZugangsdatumErgänzungsschreiben193BGB,
  versendungsdatumErgänzungsschreiben,
  chosenVersammlungstag,
  substractionWerktagToEndeLadungsfristAlt,
  fristendeLadungsfristVor193BGBAlt,
  substractionWerktagToZugangLadungAlt,
  regelmäßigesZugangsdatumLadungVor193Alt,
  versendungsdatumEinladungAlt,
  regelmäßigesZugangsdatumErgänzungsschreiben139Alt,
  substractionWerktagToZugangErgänzungschreibenAlt,
  versendungsdatumErgänzungsschreibenAlt,
}: ErgebnisTimelineProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useFristenrechnerStyles();

  const callDeadline = Number.isInteger(einberufungsfrist)
    ? einberufungsfrist
    : undefined;
  const callDeadlineString =
    callDeadline &&
    !Array.isArray(callDeadline) &&
    einberufungswochenTranslate[callDeadline];
  const callDeadlineNum = callDeadlineString && callDeadlineString.string;

  return (
    <Timeline position="right" className={classes.timeline}>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0", py: "24px", px: 2 }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          <strong>
            {buttonSwitch === false
              ? formatDate(chosenVersendungsdatum)
              : formatDate(versendungsdatumEinladungAlt)}
          </strong>

          <br />

          {checkedDetail ? (
            <TooltipFristencalculation
              calculation={
                postlaufzeitPauschal > 0
                  ? `+ ${postlaufzeitPauschal} ${
                      postlaufzeitPauschal === 1 ? "Tag" : "Tage"
                    }`
                  : ""
              }
              tooltip={
                postlaufzeitPauschal === 1
                  ? t("fristenrechner.postlaufzeit1Tag")
                  : postlaufzeitPauschal === 2
                  ? t("fristenrechner.postlaufzeit2Tag")
                  : postlaufzeitPauschal === 4
                  ? t("fristenrechner.postlaufzeit4Tag")
                  : t("fristenrechner.postlaufzeit")
              }
            />
          ) : (
            ""
          )}

          <br />

          {checkedDetail ? (
            buttonSwitch === false &&
            additionPostlaufendeNächsterWerktag > 0 ? (
              <TooltipFristencalculation
                calculation={`+ ${additionPostlaufendeNächsterWerktag} ${
                  additionPostlaufendeNächsterWerktag === 1
                    ? t("fristenrechner.daySingular")
                    : t("fristenrechner.dayPlural")
                }`}
                tooltip={t("fristenrechner.postlaufzeitAufschlag")}
              />
            ) : substractionWerktagToZugangLadungAlt > 0 ? (
              <TooltipFristencalculation
                calculation={`+ ${substractionWerktagToZugangLadungAlt} ${
                  substractionWerktagToZugangLadungAlt === 1
                    ? t("fristenrechner.daySingular")
                    : t("fristenrechner.dayPlural")
                }`}
                tooltip={t("fristenrechner.postlaufzeitAufschlag")}
              />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineDot color="primary">
            <Send />
          </TimelineDot>

          <TimelineConnector />
        </TimelineSeparator>

        <TimelineContent sx={{ py: "24px", px: 2 }}>
          <Typography variant="h6" component="span">
            <strong>
              {buttonSwitch === false
                ? t("fristenrechner.chosenDateOfDispatchOfTheInvitation")
                : t(
                    "fristenrechner.latestPermissibleDateOfDispatchOfTheInvitation"
                  )}
            </strong>
          </Typography>

          <Typography>
            {chosenVersendungsart === "post" ||
            chosenVersendungsart === "einschreiben" ||
            chosenVersendungsart === "email" ||
            chosenVersendungsart === "fax"
              ? `per ${versendungsartenTranslate[chosenVersendungsart]}`
              : ""}
          </Typography>
        </TimelineContent>
      </TimelineItem>

      {checkedDetail ? (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", py: "24px", px: 2 }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {buttonSwitch === false
              ? formatDate(regelmäßigesZugangsdatumLadung193BGB)
              : formatDate(regelmäßigesZugangsdatumLadungVor193Alt)}

            <br />

            {Array.isArray(einberufungsfrist) ? (
              ""
            ) : (
              <TooltipFristencalculation
                calculation={einberufungsfrist ? `+ ${callDeadlineNum}` : ""}
                tooltip={t("fristenrechner.chosenConvocationPeriod")}
              />
            )}

            <br />

            {buttonSwitch === false && additionFristendeNächsterWerktag > 0 ? (
              <TooltipFristencalculation
                calculation={`+ ${additionFristendeNächsterWerktag}
                  ${
                    additionFristendeNächsterWerktag === 1
                      ? t("fristenrechner.daySingular")
                      : t("fristenrechner.dayPlural")
                  }`}
                tooltip={t("fristenrechner.tooltip193")}
              />
            ) : (
              ""
            )}
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineConnector />

            <TimelineDot color="primary" />

            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "20px", px: 2 }}>
            <Typography variant="h6" component="span">
              {t("fristenrechner.receiptInvitationMeeting")}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ) : (
        ""
      )}

      {checkedAnkündigung ? (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", py: "24px", px: 2 }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <strong>
              {buttonSwitch === false
                ? formatDate(versendungsdatumErgänzungsschreiben)
                : formatDate(versendungsdatumErgänzungsschreibenAlt)}
            </strong>{" "}
            <br />
            {checkedDetail ? (
              <TooltipFristencalculation
                calculation={
                  postlaufzeitPauschal > 0
                    ? `+
          ${postlaufzeitPauschal} ${
                        postlaufzeitPauschal === 1
                          ? t("fristenrechner.daySingular")
                          : t("fristenrechner.dayPlural")
                      }`
                    : ""
                }
                tooltip={t("fristenrechner.postlaufzeit")}
              />
            ) : (
              ""
            )}
            <br />
            {checkedDetail ? (
              buttonSwitch === false ? (
                substractionWerktagToZugangsdatumErgänzungsschreiben > 0 ? (
                  <TooltipFristencalculation
                    calculation={`+ ${substractionWerktagToZugangsdatumErgänzungsschreiben}
                    ${
                      substractionWerktagToZugangsdatumErgänzungsschreiben === 1
                        ? t("fristenrechner.daySingular")
                        : t("fristenrechner.dayPlural")
                    }`}
                    tooltip={t("fristenrechner.postlaufzeitAufschlag")}
                  />
                ) : (
                  ""
                )
              ) : substractionWerktagToZugangErgänzungschreibenAlt > 0 ? (
                <TooltipFristencalculation
                  calculation={`+ ${substractionWerktagToZugangErgänzungschreibenAlt}
                    ${
                      substractionWerktagToZugangErgänzungschreibenAlt === 1
                        ? t("fristenrechner.daySingular")
                        : t("fristenrechner.dayPlural")
                    }`}
                  tooltip={t("fristenrechner.postlaufzeitAufschlag")}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineConnector />

            <TimelineDot color="secondary">
              <Send />
            </TimelineDot>

            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "24px", px: 2 }}>
            <Typography variant="h6" component="span">
              <strong>
                {t("fristenrechner.latestDateForSendingTheAgenda")}
              </strong>
            </Typography>

            <Typography>
              {chosenVersendungsart === "post" ||
              chosenVersendungsart === "einschreiben" ||
              chosenVersendungsart === "email" ||
              chosenVersendungsart === "fax"
                ? `per ${versendungsartenTranslate[chosenVersendungsart]}`
                : ""}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ) : (
        ""
      )}

      {checkedAnkündigung ? (
        checkedDetail ? (
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0", py: "24px", px: 2 }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {buttonSwitch === false
                ? formatDate(regelmäßigesZugangsdatumErgänzungsschreiben193BGB)
                : formatDate(regelmäßigesZugangsdatumErgänzungsschreiben139Alt)}

              <br />

              {checkedDetail ? (
                <TooltipFristencalculation
                  calculation={`+ 3 ${t("fristenrechner.dayPlural")}`}
                  tooltip={t("fristenrechner.tooltip51IVGmbHG")}
                />
              ) : (
                ""
              )}
            </TimelineOppositeContent>

            <TimelineSeparator>
              <TimelineConnector />

              <TimelineDot color="secondary" />

              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent sx={{ py: "24px", px: 2 }}>
              <Typography variant="h6" component="span">
                {t("fristenrechner.ReceiptOfTheAgenda")}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {checkedDetail ? (
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", py: "24px", px: 2 }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            {buttonSwitch === false
              ? formatDate(fristendeLadungsfrist193BGB)
              : formatDate(fristendeLadungsfristVor193BGBAlt)}

            <br />

            <TooltipFristencalculation
              calculation={` + 1 ${t("fristenrechner.daySingular")}`}
              tooltip={t("fristenrechner.tooltip51IGmbHG")}
            />

            <br />

            {buttonSwitch === false ? (
              ""
            ) : substractionWerktagToEndeLadungsfristAlt > 0 ? (
              <TooltipFristencalculation
                calculation={`+ ${substractionWerktagToEndeLadungsfristAlt}
                  ${
                    substractionWerktagToEndeLadungsfristAlt === 1
                      ? t("fristenrechner.daySingular")
                      : t("fristenrechner.dayPlural")
                  }`}
                tooltip={t("fristenrechner.tooltipbrückentage")}
              />
            ) : (
              ""
            )}
          </TimelineOppositeContent>

          <TimelineSeparator>
            <TimelineConnector />

            <TimelineDot color="primary" />

            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent sx={{ py: "24px", px: 2 }}>
            <Typography variant="h6" component="span">
              {t("fristenrechner.EndOfTheConvocationPeriod")}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ) : (
        ""
      )}

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0", py: "24px", px: 2 }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          <strong>
            {buttonSwitch === false ? (
              <div>
                <WarningVersammlungstagSunOrHoliday
                  date={frühesterVersammlungstag}
                  dateFormatted={formatVersammlungstag(
                    frühesterVersammlungstag
                  )}
                />

                <WarningVersendungOnTimeNotPossible
                  chosenVersendungstag={chosenVersendungsdatum}
                />
              </div>
            ) : (
              <div>
                <WarningVersammlungstagSunOrHoliday
                  date={chosenVersammlungstag}
                  dateFormatted={formatVersammlungstag(chosenVersammlungstag)}
                />

                <WarningVersammlungstagstagTooNear
                  versendungsdatumEinladungAlt={versendungsdatumEinladungAlt}
                />
              </div>
            )}
          </strong>
        </TimelineOppositeContent>

        <TimelineSeparator>
          <TimelineConnector />

          <TimelineDot color="primary">
            <Group />
          </TimelineDot>
        </TimelineSeparator>

        <TimelineContent sx={{ py: "24px", px: 2 }}>
          <Typography variant="h6" component="span">
            <strong>
              {buttonSwitch === false
                ? t("fristenrechner.earliestPermissibleDateOfMeeting")
                : t("fristenrechner.chosenMeetingDay")}
            </strong>
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default ErgebnisTimeline;
