/* eslint-disable i18next/no-literal-string */
import {
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

const FirstQuestion = ({
  buttonSwitch,
  chosenVersammlungstag,
  handleVersammlungstag,
  chosenVersendungsdatum,
  handleVersendungstag,
}: {
  buttonSwitch: boolean;
  chosenVersammlungstag: Date | null | "";
  handleVersammlungstag: (newValue: Date | null | "") => void;
  chosenVersendungsdatum: Date | "" | null;
  handleVersendungstag: (newValue: "" | Date | null) => void;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.questions}>
      {buttonSwitch === false ? (
        <>
          <Typography className={classes.additionsWrapper}>
            Wann sollen die Einladungen versandt werden?{" "}
            <Tooltip
              title={t("fristenrechner.tooltipFirstQuestionVersendungstag")}
            >
              <HelpOutline className={classes.tooltipIcon} />
            </Tooltip>
          </Typography>

          <div className={classes.form}>
            <MobileDatePicker
              mask="__.__.____"
              label="Versendungstag"
              value={chosenVersendungsdatum}
              onChange={handleVersendungstag}
              renderInput={(params) => (
                <TextField
                  fullWidth={true}
                  className={classes.innerform}
                  // 🚨 This type assertion is not accurate,
                  //     neither is the implementation.
                  //
                  //    We use a MUI v4 TextField, combining it
                  //     with a v5 MobilePicker.
                  {...(params as TextFieldProps)}
                />
              )}
            />
          </div>
        </>
      ) : (
        <>
          <Typography className={classes.additionsWrapper}>
            Wann soll die Gesellschafterversammlung stattfinden?{" "}
            <Tooltip
              title={t("fristenrechner.tooltipFirstQuestionVersammlungstag")}
            >
              <HelpOutline className={classes.tooltipIcon} />
            </Tooltip>
          </Typography>

          <div className={classes.form}>
            <MobileDatePicker
              mask="__.__.____"
              label="Tag der Versammlung"
              value={chosenVersammlungstag}
              onChange={handleVersammlungstag}
              renderInput={(params) => (
                <TextField
                  className={classes.innerform}
                  // 🚨 This type assertion is not accurate,
                  //     neither is the implementation.
                  //
                  //    We use a MUI v4 TextField, combining it
                  //     with a v5 MobilePicker.
                  {...(params as TextFieldProps)}
                />
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FirstQuestion;
