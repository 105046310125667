/* eslint-disable i18next/no-literal-string */
import { Tooltip, Typography } from "@material-ui/core";
import { Group, HelpOutline, Send } from "@material-ui/icons";
import { Button } from "gatsby-material-ui-components";
import { useTranslation } from "react-i18next";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

export interface ButtonVersendungstagVersammlungsTagProps {
  countingFromDayOfVersammlung: boolean;
  setToCountingFromVersendung: () => void;
  setToCountingFromVersammlung: () => void;
}

const ButtonVersendungstagVersammlungstag = ({
  countingFromDayOfVersammlung,
  setToCountingFromVersendung,
  setToCountingFromVersammlung,
}: ButtonVersendungstagVersammlungsTagProps): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.questions}>
      <Typography className={classes.additionsWrapper}>
        Von welchem Datum soll die Fristberechnung ausgehen?{" "}
        <Tooltip
          title={t("fristenrechner.tooltipVersendungstagVersammlungstag")}
        >
          <HelpOutline className={classes.tooltipIcon} />
        </Tooltip>
      </Typography>

      {countingFromDayOfVersammlung === false ? (
        <div className={classes.form}>
          <Button
            variant="outlined"
            onClick={setToCountingFromVersammlung}
            startIcon={<Group />}
          >
            Tag der Versammlung
          </Button>

          <Button
            variant="contained"
            onClick={setToCountingFromVersendung}
            startIcon={<Send />}
          >
            Tag der Versendung
          </Button>
        </div>
      ) : (
        <div className={classes.form}>
          <Button
            variant="contained"
            onClick={setToCountingFromVersammlung}
            startIcon={<Group />}
          >
            Tag der Versammlung
          </Button>

          <Button
            variant="outlined"
            onClick={setToCountingFromVersendung}
            startIcon={<Send />}
          >
            Tag der Versendung
          </Button>
        </div>
      )}
    </div>
  );
};

export default ButtonVersendungstagVersammlungstag;
