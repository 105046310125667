/* eslint-disable i18next/no-literal-string */
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { versendungsarten } from "../fristenrechnerConstants";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

const ThirdQuestion = ({
  chosenVersendungsart,
  ausländischerGesellschafter,
  handleAusländischerGesellschafter,
}: {
  chosenVersendungsart: string;
  ausländischerGesellschafter: string;
  handleAusländischerGesellschafter: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.questions}>
      {chosenVersendungsart !== versendungsarten[2].value &&
      chosenVersendungsart !== versendungsarten[3].value ? (
        <div>
          <Typography className={classes.additionsWrapper}>
            Wohnen ein oder mehrere Gesellschafter im Ausland?{" "}
            <Tooltip title={t("fristenrechner.tooltipThirdQuestion")}>
              <HelpOutline className={classes.tooltipIcon} />
            </Tooltip>
          </Typography>

          <FormControl>
            <RadioGroup
              row={true}
              className={classes.form}
              aria-labelledby="ausländischer-gesellschafter"
              name="ausländischer-gesellschafter"
              value={ausländischerGesellschafter}
              onChange={handleAusländischerGesellschafter}
            >
              <FormControlLabel value="nein" control={<Radio />} label="Nein" />

              <FormControlLabel value="ja" control={<Radio />} label="Ja" />
            </RadioGroup>
          </FormControl>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ThirdQuestion;
