/* eslint-disable i18next/no-literal-string */
import { MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { versendungsarten } from "../fristenrechnerConstants";
import { useFristenrechnerStyles } from "../FristenrechnerStyles";

const SecondQuestion = ({
  chosenVersendungsart,
  handleVersendungsart,
}: {
  chosenVersendungsart: string;
  handleVersendungsart: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}): JSX.Element => {
  const classes = useFristenrechnerStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.questions}>
      <Typography className={classes.additionsWrapper}>
        <span>Wie werden Einladungen an die Gesellschafter versandt?</span>

        <Tooltip title={t("fristenrechner.tooltipSecondQuestion")}>
          <HelpOutline className={classes.tooltipIcon} />
        </Tooltip>
      </Typography>

      <TextField
        className={classes.innerform}
        select={true}
        value={chosenVersendungsart}
        label={"Versendungsart"}
        onChange={handleVersendungsart}
      >
        {versendungsarten.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default SecondQuestion;
