import {
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  subDays,
} from "date-fns";
import { isHoliday } from "feiertagejs";

// Outputs amount of days to get before workingday
export function getSubDays193BGB(fristende: Date | ""): number {
  // check if Sunday and no following Holidays
  if (fristende === "") {
    return 0;
  } else if (
    isSaturday(fristende) &&
    !isHoliday(subDays(fristende, 1), "ALL")
  ) {
    return 1;
  } else if (
    isSaturday(fristende) &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    !isHoliday(subDays(fristende, 2), "ALL")
  ) {
    return 2;
  } else if (
    isSaturday(fristende) &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    !isHoliday(subDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isSaturday(fristende) &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    !isHoliday(subDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else if (
    isSaturday(fristende) &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    isHoliday(subDays(fristende, 4), "ALL") &&
    !isHoliday(subDays(fristende, 5), "ALL")
  ) {
    return 5;
  }

  // check if Saturday and on Monday and the following days no Holidays
  else if (isSunday(fristende) && !isHoliday(subDays(fristende, 2), "ALL")) {
    return 2;
  } else if (
    isSunday(fristende) &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    !isHoliday(subDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isSunday(fristende) &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    !isHoliday(subDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else if (
    isSunday(fristende) &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    isHoliday(subDays(fristende, 4), "ALL") &&
    !isHoliday(subDays(fristende, 5), "ALL")
  ) {
    return 5;
  } else if (
    isSunday(fristende) &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    isHoliday(subDays(fristende, 4), "ALL") &&
    isHoliday(subDays(fristende, 5), "ALL") &&
    !isHoliday(subDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Friday is Holiday and on Monday and the following days no Holidays
  else if (
    isMonday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(subDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isMonday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    !isHoliday(subDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else if (
    isMonday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    isHoliday(subDays(fristende, 4), "ALL") &&
    !isHoliday(subDays(fristende, 5), "ALL")
  ) {
    return 5;
  } else if (
    isMonday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    isHoliday(subDays(fristende, 4), "ALL") &&
    isHoliday(subDays(fristende, 5), "ALL") &&
    !isHoliday(subDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Thursday is Holiday and on Friday and Monday and the following days no Holidays
  else if (
    isTuesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(subDays(fristende, 1), "ALL")
  ) {
    return 1;
  } else if (
    isTuesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    !isHoliday(subDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else if (
    isTuesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 4), "ALL") &&
    !isHoliday(subDays(fristende, 5), "ALL")
  ) {
    return 5;
  } else if (
    isTuesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 4), "ALL") &&
    isHoliday(subDays(fristende, 5), "ALL") &&
    !isHoliday(subDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Wednesay is Holiday and on Thursday,Friday, Monday and the following days no Holidays
  else if (
    isWednesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(subDays(fristende, 1), "ALL")
  ) {
    return 1;
  } else if (
    isWednesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    !isHoliday(subDays(fristende, 2), "ALL")
  ) {
    return 2;
  } else if (
    isWednesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    !isHoliday(subDays(fristende, 5), "ALL")
  ) {
    return 5;
  } else if (
    isWednesday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    isHoliday(subDays(fristende, 5), "ALL") &&
    !isHoliday(subDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Tuesday is Holiday and on Wedenesday, Thursday,Friday, Monday and the following days no Holidays
  else if (
    isThursday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(subDays(fristende, 1), "ALL")
  ) {
    return 1;
  } else if (
    isThursday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    !isHoliday(subDays(fristende, 2), "ALL")
  ) {
    return 2;
  } else if (
    isThursday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    !isHoliday(subDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isThursday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    !isHoliday(subDays(fristende, 6), "ALL")
  ) {
    return 6;
  }

  // check if Monday is Holiday and on  the following days no Holidays
  else if (
    isFriday(fristende) &&
    isHoliday(fristende, "ALL") &&
    !isHoliday(subDays(fristende, 1), "ALL")
  ) {
    return 1;
  } else if (
    isFriday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    !isHoliday(subDays(fristende, 2), "ALL")
  ) {
    return 2;
  } else if (
    isFriday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    !isHoliday(subDays(fristende, 3), "ALL")
  ) {
    return 3;
  } else if (
    isFriday(fristende) &&
    isHoliday(fristende, "ALL") &&
    isHoliday(subDays(fristende, 1), "ALL") &&
    isHoliday(subDays(fristende, 2), "ALL") &&
    isHoliday(subDays(fristende, 3), "ALL") &&
    !isHoliday(subDays(fristende, 4), "ALL")
  ) {
    return 4;
  } else {
    return 0;
  }
}
